<template>
  <div id="header-component">
    <div class="header-desktop">
      <div class="d-flex justify-content-between p-3">
        <div class="col-12 mt-3">
          {{ /* HEADER SHOP */ }}
          <div class="d-flex justify-content-between">
            <div>
              <router-link class="" :to="{ name: 'sale', params: {id: this.$store.getters.getCurrentUser.shop.id }}">
                <img :src="require('../../../public/images/'+ ChannelModule.channel.code +'/logo.svg')" alt="Logo" width="200">
              </router-link>
            </div>
            <div>
              <div class="d-flex">
                <div class="mt-3">
                  <i>Bienvenue, <u>{{ this.$store.getters.getCurrentUser.firstname }} {{ this.$store.getters.getCurrentUser.lastname }}</u></i>
                </div>

                <b-dropdown id="dropdown-right" right text="Right align" variant="link" toggle-class="text-decoration-none" no-caret>
                  <template #button-content>
                    <div class="icon-user-header ml-2">
                      <b-icon icon="person" font-scale="1.5" variant="primary"></b-icon>
                    </div>
                  </template>
                  <b-dropdown-item :href="'/user/profile/' + this.$store.getters.getCurrentUser.id">Ma page profil</b-dropdown-item>
                  <b-dropdown-item href="/logout">Se déconnecter</b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {{ /* HEADER MOBILE */ }}
    <div class="header-mobile-shop">
      {{ /* HEADER SHOP */ }}
      <div class="d-flex justify-content-between">
        <div class="mt-4 ml-2">
          <img :src="require('../../../public/images/'+ ChannelModule.channel.code +'/logo.svg')" alt="Logo">
        </div>
        <div class="mt-4 mr-2">
          <b-dropdown id="dropdown-right" right text="Right align" variant="link" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <div class="icon-user-header ml-2">
                <b-icon icon="person" font-scale="1.5" variant="primary"></b-icon>
              </div>
            </template>
            <b-dropdown-item :href="'/user/profile/' + this.$store.getters.getCurrentUser.id">Ma page profil</b-dropdown-item>
            <b-dropdown-item href="/logout">Se déconnecter</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  props: {
    showSidebar: {
      type: Boolean
    },
  },
  methods: {

  },
  created() {
  },
  computed: {
    ...mapState(['ChannelModule']),
    ...mapState(['AuthModule']),
    ...mapState(['UserModule']),
  },
}
</script>

<style scoped>

</style>
